<template>
	<div id="base">
		<h1 class="title-search">Statistiques</h1>
		<div class="container">
			<Card
				v-for="( card, key ) in cards"
				:key="key"
				:ref="'card-' + key"
				:name="key"
				:title="card.title"
				:sections="card.sections"
				v-if="!card.hide"
				@period-changed="periodChanged( card, $event )"/>
			<!--	v-show="card.hasData"-->
		</div>
		<div id="loading-logo" class="fas fa-circle-notch" :class="{ visible: isLoading }"></div>
	</div>
</template>


<script>
	import dayjs from 'dayjs'
	import dayOfYear from 'dayjs/plugin/dayOfYear'
	import Card from './stats/Card.vue'

	dayjs.extend(dayOfYear)

	export default {
		components: {
			Card
		},
		data() {
			return {
				/*
				period: "month",
				unit: "day",
				unitformat: "DD MMM",
				format: "DD MMM",
				*/
				isLoading: false,
				period: "year",
				unit: "month",
				unitformat: "MMM YYYY",
				format: "MMM YYYY",
				cards: {
					appointments: {
						title: this.$t('common.appoints'),
						sections: [
							{
								title: this.$t('statistics.resume'),
								data: {
									barChartData: { labels: [], datasets: [] },
									lineChartData: { labels: [], datasets: [] },
									pieChartData: { datasets: [], labels: [] },
									summaryData: { labels: [], data: [] },
									lineChartTitle: this.$t( "statistics.apps" ),
									barChartTitle: this.$t( "statistics.appsperhours" ),
									pieChartTitle: this.$t( "statistics.online" ),
									summaryTitle: this.$t( "statistics.inshort" )
								}
							},
							{
								title: this.$t('statistics.general'),
								data: {
									barChartData: { labels: [], datasets: [] },
									lineChartData: { labels: [], datasets: [] },
									pieChartData: { datasets: [] },
									summaryData: { labels: [], data: [] },
									lineChartTitle: this.$t( "statistics.apps" ),
									barChartTitle: this.$t( "statistics.appsperhours" ),
									pieChartTitle: this.$t( "statistics.appfinishcanceled" ),
									summaryTitle: this.$t( "statistics.inshort" )
								},
							},
							{
								title: this.$t('statistics.online'),
								data: {
									barChartData: { labels: [], datasets: [] },
									lineChartData: { labels: [], datasets: [] },
									pieChartData: { datasets: [] },
									summaryData: { labels: [], data: [] },
									lineChartTitle: this.$t( "statistics.apps" ),
									barChartTitle: this.$t( "statistics.appsperhours" ),
									pieChartTitle: this.$t( "common.age" ),
									summaryTitle: this.$t( "statistics.inshort" )
								},
							},
						]
					},
					cosmetics: {
						title: this.$t('common.cosmetics'),
						sections: [
							{
								title: this.$t('statistics.resume'),
								data: {
									barChartData: { labels: [], datasets: [] },
									lineChartData: { labels: [], datasets: [] },
									pieChartData: { datasets: [] },
									summaryData: { labels: [], data: [] },
									lineChartTitle: this.$t( "statistics.brand" ),
									barChartTitle: this.$t( "statistics.globaluse" ),
									pieChartTitle: this.$t( "statistics.topranges" ),
									summaryTitle: this.$t( "statistics.inshort" )
								}
							},
							{
								title: this.$t('common.brands'),
								data: {
									barChartData: { labels: [], datasets: [] },
									lineChartData: { labels: [], datasets: [] },
									pieChartData: { datasets: [] },
									summaryData: { labels: [], data: [] },
									lineChartTitle: this.$t( "statistics.bestuse" ),
									barChartTitle: this.$t( "statistics.globaluse" ),
									pieChartTitle: this.$t( "statistics.toptypes" ),
									summaryTitle: this.$t( "statistics.top3" )
								},
							},
							{
								title: this.$t('stocks.ranges'),
								data: {
									barChartData: { labels: [], datasets: [] },
									lineChartData: { labels: [], datasets: [] },
									pieChartData: { datasets: [] },
									summaryData: { labels: [], data: [] },
									lineChartTitle: this.$t( "statistics.bestuse" ),
									barChartTitle: this.$t( "statistics.globaluse" ),
									pieChartTitle: this.$t( "statistics.toptypes" ),
									summaryTitle: this.$t( "statistics.top3" )
								},
							},
						],
						hide: !this.$store.getters.salonHasStocks(),
						hasData: true,
					},
					staff: {
						title: this.$t('administration.team'),
						sections: [
							{
								title: this.$t('statistics.resume'),
								data: {
									barChartData: { labels: [], datasets: [] },
									lineChartData: { labels: [], datasets: [] },
									pieChartData: { datasets: [] },
									summaryData: { labels: [], data: [] },
									lineChartTitle: this.$t( "statistics.hours" ),
									barChartTitle: this.$t( "statistics.days" ),
									pieChartTitle: this.$t( "statistics.attendancerate" ),
									summaryTitle: this.$t( "statistics.inshort" ),
									lineChartOptions: { scales: { yAxes: [{ ticks: {
										stepSize: 1,
										min: 0,
										suggestedMax: 1,
										suggestedMin: 0,
										beginAtZero: true,
										callback: (v) => {
											if ( v > 0 && v < 1 ) {
												return ""
											}
											return this.$tc( "time.shorthours", parseInt(v) )
										}
									}}]}}
								}
							}
						]
					},
					customers: {
						title:  this.$t('common.customers'),
						sections: [
							{
								title: this.$t('statistics.resume'),
								data: {
									barChartData: { labels: [], datasets: [] },
									lineChartData: { labels: [], datasets: [] },
									pieChartData: { datasets: [] },
									summaryData: { labels: [], data: [] },
									lineChartTitle: this.$t( "statistics.totalsub" ),
									barChartTitle: this.$t( "statistics.totalnewsub" ),
									pieChartTitle: this.$t( "common.age" ),
									summaryTitle: this.$t( "statistics.inshort" ),
									lineChartOptions: { scales: { yAxes: [{ ticks: {
										stepSize: 1
									}}]}}
								}
							},
							{
								title: this.$t('statistics.top100'),
								data: {
									listData: []
								},
							},
							{
								title: this.$t('statistics.details'),
								data: {
									barChartData: { labels: [], datasets: [] },
									lineChartData: { labels: [], datasets: [] },
									pieChartData: { datasets: [] },
									summaryData: { labels: [], data: [] },
									lineChartTitle: this.$t( "statistics.totalsub" ),
									barChartTitle: this.$t( "statistics.totalnewoldsub" ),
									pieChartTitle: this.$t( "common.gender" ),
									summaryTitle: this.$t( "statistics.inshort" ),
									barChartOptions: {
										responsive: true,
										maintainAspectRatio: true,
										scales: {
											xAxes: [{
												stacked: true,
											}],
											yAxes: [{
												stacked: true,
												ticks: {
													stepSize: 1
												}
											}]
										},
										legend: {
											position: "bottom",
										}
									},
								},
							},
						]
					}
				}
			}
		},
		watch: {
			'$route'( value, oldValue ) {
				if ( value.path === "/stats" ) {
					this.update()
				} else if ( oldValue.path === "/stats" ) {
					(async () => {
						// Clear data to reduce memory usage
						for ( let card in this.cards ) {
							this.cards[card].sections.forEach( ( section, i ) => {
								if ( section.data.barChartData ) {
									section.data.barChartData.labels = []
									section.data.barChartData.datasets = []
									this.$refs["card-" + card][0].$refs["layout-" + i][0].$refs["barChart"].update()
								}
								if ( section.data.lineChartData ) {
									section.data.lineChartData.labels = []
									section.data.lineChartData.datasets = []
									this.$refs["card-" + card][0].$refs["layout-" + i][0].$refs["lineChart"].update()
								}
								if ( section.data.pieChartData ) {
									section.data.pieChartData.datasets = []
									this.$refs["card-" + card][0].$refs["layout-" + i][0].$refs["pieChart"].update()
								}
								if ( section.data.summaryData ) {
									section.data.summaryData.labels = []
									section.data.summaryData.data = []
								}
							})
						}
						while( this.cards.staff.sections.length > 1 ) {
							delete this.cards.staff.sections.splice( 1, 1 )
						}
					})()
				}
			}
		},
		methods: {
			sameOrBefore( d1, d2 ) {
				return d1.isSame( d2 ) || d1.isBefore( d2 )
			},
			sameOrAfter( d1, d2 ) {
				return d1.isSame( d2 ) || d1.isAfter( d2 )
			},
			periodChanged( card, period ) {
				Object.entries(this.cards).forEach( ([key, val]) => {
					if (val.hide) return
					if ( this.$refs["card-" + key][0] !== card ) {
						this.$refs["card-" + key][0].setPeriod( period )
					}
				})
				if ( period == this.period ) {
					// Already set, do nothing
					return
				}
				this.period = period
				if ( period == "day" ) {
					this.unit = "hour"
					this.unitformat = "HH"
					this.format = "HH"
				} else if ( period == "week" ) {
					this.unit = "day"
					this.unitformat = "ddd"
					this.format = "ddd"
				} else if ( period == "month" ) {
					this.unit = "day"
					this.unitformat = "DD MMM"
					this.format = "DD MMM"
				} else if ( period == "year" ) {
					this.unit = "month"
					this.unitformat = "MMM YYYY"
					this.format = "MMM YYYY"
				}

				while( this.cards.staff.sections.length > 1 ) {
					delete this.cards.staff.sections.splice( 1, 1 )
				}
				this.update()
			},
			async appointmentsByHour( openHour, closeHour, closeHourMorning, openHourAfternoon, appointments ) {
				const style = this.style
				let hours = []
				let hoursOnline = []
				appointments.forEach( appointment => {
					if ( appointment.status && appointment.status.indexOf( "finished" ) >= 0 ) {
						var h = new Date( appointment.date ).getHours()
						hours[h] = hours[h] || 0
						hours[h]++
						if ( !appointment.takerid ) {
							hoursOnline[h] = hoursOnline[h] || 0
							hoursOnline[h]++
						}
					}
				});
				let apply = function( label, dest, set ) {
					dest.labels = [];
					dest.datasets = [];
					dest.datasets[0] = {
						categoryPercentage: 0.5,
						barPercentage: 1,
						label: label,
						backgroundColor: "#d6deb9",
						data: []
					};
				/*	dest.datasets[1] = {
						label: "Heures",
						backgroundColor: "green",
						data: []
					};*/
					for ( var i = openHour; i <= closeHour; i++ ) {
						dest.labels.push( i + "h" );
						dest.datasets[0].data.push( set[i] );
					//	dest.datasets[1].data.push( 10 );
					}
				}
				apply( this.$t( "statistics.appsperhour" ), this.cards.appointments.sections[0].data.barChartData, hours )
				apply( this.$t( "statistics.appsperhour" ), this.cards.appointments.sections[1].data.barChartData, hours )
				apply( this.$t( "statistics.onlineperhour" ), this.cards.appointments.sections[2].data.barChartData, hoursOnline )
				this.$refs["card-appointments"][0].$refs["layout-0"][0].$refs["barChart"].update()
				this.$refs["card-appointments"][0].$refs["layout-1"][0].$refs["barChart"].update()
				this.$refs["card-appointments"][0].$refs["layout-2"][0].$refs["barChart"].update()
				let min = openHour
				let max = openHour
				let minOnline = openHour
				let maxOnline = openHour
				for ( let i = openHour; i <= closeHour; i++ ) {
					if ( i < closeHourMorning || i >= openHourAfternoon ) {
						if ( !hours[min] || hours[i] < hours[min] ) {
							min = i
						}
						if ( !hours[max] || hours[i] > hours[max] ) {
							max = i
						}
						if ( !hoursOnline[minOnline] || hoursOnline[i] < hoursOnline[minOnline] ) {
							minOnline = i
						}
						if ( !hoursOnline[maxOnline] || hoursOnline[i] > hoursOnline[maxOnline] ) {
							maxOnline = i
						}
					}
				}
				return { min, max, minOnline, maxOnline }
			},
			async appointmentsByDate( appointments, startTime, endTime, unit ) {
				const style = this.style
				let apply = function( thiz, dest, online ) {
					dest.labels = []
					dest.datasets = []
					dest.datasets[0] = {
						label: thiz.$t( "titles.appoint" ),	backgroundColor:Color("#d6deb9").alpha( 0.25 ).rgbaString(),
						borderColor: Color("#d6deb9").darken(0.4) .rgbaString(),
						data: []
					}
					let time = dayjs( startTime )
					let iApp = 0
					let biggest = 0
					let biggestText = ""
					while ( time.isBefore(endTime) ) {
						const nextTime = time.add( 1, unit )
						let count = 0
						while ( iApp < appointments.length && dayjs(appointments[iApp].date).isBefore( nextTime ) ) {
							if ( dayjs(appointments[iApp].date).isAfter( time ) ) {
								if ( appointments[iApp].status && appointments[iApp].status.indexOf( "finished" ) >= 0 && ( !online || ( online && !appointments[iApp].takerid ) ) ) {
									count++
								}
							}
							iApp++;
						}
						if ( count > biggest ) {
							biggest = count
							biggestText = time.format( thiz.format )
						}
						dest.labels.push( time.format( thiz.format ) )
						dest.datasets[0].data.push( count )
						time = nextTime
					}
					return biggestText
				}
				apply( this, this.cards.appointments.sections[0].data.lineChartData )
				apply( this, this.cards.appointments.sections[1].data.lineChartData )
				const biggestOnline = apply( this, this.cards.appointments.sections[2].data.lineChartData, true )
				this.$refs["card-appointments"][0].$refs["layout-0"][0].$refs["lineChart"].update()
				this.$refs["card-appointments"][0].$refs["layout-1"][0].$refs["lineChart"].update()
				this.$refs["card-appointments"][0].$refs["layout-2"][0].$refs["lineChart"].update()
				return biggestOnline
			},
			async appointmentsTakers( appointments, now ) {
				let insalon = appointments.filter( app => app.status && app.status.indexOf("finished") >= 0 && app.takerid && !isNaN(app.takerid) ).length
				let online = appointments.filter( app => app.status && app.status.indexOf("finished") >= 0 && !app.takerid ).length
				let chart = this.cards.appointments.sections[0].data.pieChartData
				chart.labels = [ this.$t( "statistics.online" ) + " : " + ( online ), this.$t( "statistics.hdressorphone" ) + " : " + insalon ]
				chart.datasets[0] = {
					backgroundColor: [ "#d6deb9", Color("#d6deb9").darken(0.2).rgbaString() ],
					data: [ online || 0, insalon || 0 ]
				}
				this.$refs["card-appointments"][0].$refs["layout-0"][0].$refs["pieChart"].update()

				let u25 = 0
				let u25_40 = 0
				let u40 = 0
				appointments.forEach( app => {
					if ( app.user && !app.user.unregistration ) {
						let diff = dayjs(now).diff( dayjs(app.user.birthdate), "years" )
						if ( diff < 25 ) {
							u25++
						} else if ( diff < 40 ) {
							u25_40++
						} else {
							u40++
						}
					}
				})
				let chart2 = this.cards.appointments.sections[2].data.pieChartData
				chart2.labels = [
					this.$tc( "time.age", "- 25" ) + " : " + u25,
					this.$tc( "time.agerange", null, { start: 25, end: 40 } ) + " : " + u25_40,
					this.$tc( "time.age", "+ 40" ) + " : " + u40,
				]
				chart2.datasets[0] = {
					backgroundColor: [ "#d6deb9", Color("#d6deb9").darken(0.2).rgbaString(),Color("#d6deb9").darken(0.4).rgbaString() ],
					data: [ u25, u25_40, u40 ]
				}
				this.$refs["card-appointments"][0].$refs["layout-2"][0].$refs["pieChart"].update()

				let topAge = "40+"
				if ( u25 >= u25_40 && u25 >= u40 ) {
					topAge = "-25"
				} else if ( u25_40 >= u25 && u25_40 >= u40 ) {
					topAge = "25-40"
				}
				return { insalon, online, topAge }
			},
			async appointmentsSummary( appointments, counts, max ) {
				let summary = this.cards.appointments.sections[0].data.summaryData
				summary.labels = [
					this.$t( "statistics.hdressorphone" ),
					this.$t( "statistics.online" ),
					this.$t( "statistics.total" ),
					this.$t( "statistics.rush" ),
				]
				summary.data = [
					counts.insalon,
					counts.online,
					counts.insalon + counts.online,
					this.$tc( "time.shorthours", max )
				]
			},
			async appointmentsFinishedCanceled( appointments ) {
				let finished = appointments.filter( app => app.status && app.status.indexOf("finished") >= 0 ).length
				let canceled = appointments.filter( app => app.status && app.status.indexOf("canceled") >= 0 ).length
				let chart = this.cards.appointments.sections[1].data.pieChartData
				chart.labels = [ this.$t( "statistics.appfinished" ) + " : " + ( finished ), this.$t( "statistics.appcanceled" ) + " : " + canceled ]
				chart.datasets[0] = {
					backgroundColor: [ Color("#d6deb9").darken(0.2).rgbaString(), "#d6deb9" ],
					data: [ finished, canceled ]
				}
				this.$refs["card-appointments"][0].$refs["layout-1"][0].$refs["pieChart"].update()
				return { finished, canceled }
			},
			async appointmentsGeneralSummary( appointments, ratio, min ) {
				let summary = this.cards.appointments.sections[1].data.summaryData
				summary.labels = [
					this.$t( "statistics.appfinished" ),
					this.$t( "statistics.appcanceled" ),
					this.$t( "statistics.total" ),
					this.$t( "statistics.offpeak" ),
				]
				summary.data = [
					ratio.finished,
					ratio.canceled,
					ratio.finished + ratio.canceled,
					this.$tc( "time.shorthours", min )
				]
			},
			async appointmentsOnlineSummary( appointments, counts, min, max, topAppointments, topAge ) {
				let summary = this.cards.appointments.sections[2].data.summaryData
				summary.labels = [
					this.$t( "statistics.total" ),
					this.$t( "statistics.rush" ),
					this.$t( "statistics.topwork." + this.unit ),
					this.$t( "statistics.topage" ),
				]
				summary.data = [
					counts.online,
					this.$tc( "time.shorthours", max ),
					topAppointments,
					topAge,
				]
			},
			async usersByDate( users, baseUsersCount, startTime, endTime, unit ) {
				const style = this.style
				let users_unreg = users.filter( u => dayjs(u.unregistration).isValid() ).sort( ( a, b ) => dayjs(a.unregistration).diff( dayjs(b.unregistration) ) )
				users = users.filter( u => dayjs(u.registration).isValid() ).sort( ( a, b ) => dayjs(a.registration).diff( dayjs(b.registration) ) )
				console.log(  "COUNT", users.filter( a => !!a.registration ).map( u => u.registration ) )
				let apply = function( thiz, dest, destNew, destNew2 ) {
					dest.labels = []
					dest.datasets = []
					dest.datasets[0] = {
						label: thiz.$t( "statistics.users" ),
						backgroundColor: "#ffdfba",
						borderColor:  Color("#ffdfba").darken(0.2) .rgbaString(),
						data: []
					}
					destNew.labels = []
					destNew.datasets = [
						{
							categoryPercentage: 0.5,
							barPercentage: 1,
							label: thiz.$t( "statistics.newusers" ),
							backgroundColor: "#ffdfba",
							data: []
						}
					]
					destNew2.labels = []
					destNew2.datasets = [
						{
							categoryPercentage: 0.5,
							barPercentage: 1,
							label: thiz.$t( "statistics.newusers" ),
							backgroundColor: "#ffdfba",
							data: []
						},
						{
							categoryPercentage: 0.5,
							barPercentage: 1,
							label: thiz.$t( "statistics.leftusers" ),
							backgroundColor: Color("#ffdfba").darken(0.2) .rgbaString(),
							data: []
						}
					]
					let time = dayjs( startTime )
					let iCount = 0
					let iUser = 0
					let iUserUnreg = 0
					let accum = baseUsersCount
					while ( time.isBefore(endTime) ) {
						console.log( time.format( "YYYY-MM-DD HH:mm" ), dayjs(endTime).format( "YYYY-MM-DD HH:mm" ) )
						let accumNew = 0
						let accumLeft = 0
						const nextTime = time.add( 1, unit )
					//	while ( iUser < users.length && thiz.sameOrBefore( dayjs(users[iUser].registration), nextTime ) ) {
						while ( iUser < users.length && dayjs(users[iUser].registration).isBefore( nextTime ) ) {
							if ( thiz.sameOrAfter( dayjs(users[iUser].registration), time ) ) {
								accum++
								accumNew++
							}
							iUser++
						}
					//	while ( iUserUnreg < users_unreg.length && users_unreg[iUserUnreg].unregistration && thiz.sameOrBefore( dayjs(users_unreg[iUserUnreg].unregistration), nextTime ) ) {
						while ( iUserUnreg < users_unreg.length && users_unreg[iUserUnreg].unregistration && dayjs(users_unreg[iUserUnreg].unregistration).isBefore( nextTime ) ) {
							if ( thiz.sameOrAfter( dayjs(users_unreg[iUserUnreg].unregistration), time ) ) {
								accum--
								accumLeft--
							}
							iUserUnreg++
						}
						dest.labels.push( time.format( thiz.format ) )
						dest.datasets[0].data.push( accum )
						destNew.labels.push( time.format( thiz.format ) )
						destNew.datasets[0].data.push( accumNew )
						destNew2.labels.push( time.format( thiz.format ) )
						destNew2.datasets[0].data.push( accumNew )
						destNew2.datasets[1].data.push( accumLeft )
						time = nextTime
						iCount++
					}
				}
				apply( this, this.cards.customers.sections[0].data.lineChartData, this.cards.customers.sections[0].data.barChartData, this.cards.customers.sections[2].data.barChartData )
				this.$refs["card-customers"][0].$refs["layout-0"][0].$refs["lineChart"].update()
				this.$refs["card-customers"][0].$refs["layout-0"][0].$refs["barChart"].update()
				this.$refs["card-customers"][0].$refs["layout-2"][0].$refs["barChart"].update()
			},
			async usersByAge( usersBirthdates, now ) {
				let u25 = 0
				let u25_40 = 0
				let u40 = 0

				usersBirthdates.forEach( user => {
					if ( !user.unregistration ) {
						let diff = dayjs(now).diff( dayjs(user.birthdate), "years" )
						if ( diff < 25 ) {
							u25++
						} else if ( diff < 40 ) {
							u25_40++
						} else {
							u40++
						}
					}
				})

				let chart = this.cards.customers.sections[0].data.pieChartData
				chart.labels = [
					this.$tc( "time.age", "- 25" ) + " : " + u25,
					this.$tc( "time.agerange", null, { start: 25, end: 40 } ) + " : " + u25_40,
					this.$tc( "time.age", "+ 40" ) + " : " + u40,
				]
				chart.datasets[0] = {
					backgroundColor: [ "#ffdfba",  Color("#ffdfba").darken(0.2) .rgbaString(),  Color("#ffdfba").darken(0.4) .rgbaString() ],
					data: [ u25, u25_40, u40 ]
				}
				this.$refs["card-customers"][0].$refs["layout-0"][0].$refs["pieChart"].update()

				if ( u25 >= u25_40 && u25 >= u40 ) {
					return "-25"
				} else if ( u25_40 >= u25 && u25_40 >= u40 ) {
					return "25-40"
				}
				return "40+"
			},
			async usersSummary( usersCount, baseUsersCount, users, ageTop, now ) {
				let summary = this.cards.customers.sections[0].data.summaryData
				summary.labels = [
					this.$t( "statistics.rangestart" ),
					this.$t( "statistics.newexit" ),
					this.$t( "statistics.total" ),
					this.$t( "statistics.topage" ),
				]
				summary.data = [
					baseUsersCount,
					users.filter( user => !user.unregistration ).length + " / -" + users.filter( user => user.unregistration ).length,
					usersCount - users.filter( user => user.unregistration ).length,
					ageTop
				]
			},
			async usersTop( topUsers ) {
				this.cards.customers.sections[1].data.listData = []
				let dest = this.cards.customers.sections[1].data.listData
				topUsers.forEach( user => {
					dest.push({
						text: user.fname + " " + user.lname,
						link: "/user?id=" + user.id,
						details: [
							this.$tc( "common.points", user.loyalty ),
							{ [user.phone]: "tel:" + user.phone }
						]
					})
				})
			},
			async usersAnonymous( appointments, startTime, endTime, unit ) {
				const style = this.style
				let chart = this.cards.customers.sections[2].data.lineChartData
				chart.labels = []
				chart.datasets = [
					{
						label: this.$t( "common.registered" ),
						backgroundColor:"#ffdfba",
						borderColor: Color("#ffdfba").darken(0.4) .rgbaString(),
						data: []
					},
					{
						label: this.$tc( "common.anonymous", 2 ),
						backgroundColor: Color("#ffdfba").darken(0.2) .rgbaString(),
						borderColor: Color("#ffdfba").darken(0.2) .rgbaString(),
						data: []
					}
				]

				let time = dayjs( startTime )
				let iApp = 0
				while ( time.isBefore(endTime) ) {
					const nextTime = time.add( 1, unit )
					let totalRegistered = 0
					let totalAnon = 0
					while ( iApp < appointments.length && dayjs(appointments[iApp].date).isBefore( nextTime ) ) {
						if ( dayjs(appointments[iApp].date).isAfter( time ) ) {
							if ( appointments[iApp].status && appointments[iApp].status.indexOf( "finished" ) >= 0 ) {
								if ( appointments[iApp].userid && appointments[iApp].userid > 0 ) {
									totalRegistered++
								} else {
									totalAnon++
								}
							}
						}
						iApp++;
					}
					chart.labels.push( time.format( this.format ) )
					chart.datasets[0].data.push( totalRegistered )
					chart.datasets[1].data.push( totalAnon )
					time = nextTime
				}

				this.$refs["card-customers"][0].$refs["layout-2"][0].$refs["lineChart"].update()
			},
			async usersGenders( users ) {
				let males = 0
				let females = 0

				users.forEach( user => {
					if ( !user.unregistration ) {
						if ( user.gender == "male" ) {
							males++
						} else if ( user.gender == "female" ) {
							females++
						}
					}
				})

				let chart = this.cards.customers.sections[2].data.pieChartData
				chart.labels = [
					this.$t("common.wom") + " : " + females,
					this.$t("common.men") + " : " + males
				]
				chart.datasets[0] = {
					backgroundColor: [ "#ffdfba",  Color("#ffdfba").darken(0.2) .rgbaString(),  Color("#ffdfba").darken(0.4) .rgbaString()],
					data: [ females, males ]
				}
				this.$refs["card-customers"][0].$refs["layout-2"][0].$refs["pieChart"].update()

				return { males, females }
			},
			async usersDetailsSummary( users, genders ) {
				let summary = this.cards.customers.sections[2].data.summaryData
				summary.labels = [
					this.$t( "statistics.rangestart" ),
					this.$t( "statistics.newexit" ),
					this.$t( "statistics.total" ),
					this.$t( "statistics.topgender" ),
				]
				summary.data = [
					0,
					users.filter( user => !user.unregistration ).length + " / -" + users.filter( user => user.unregistration ).length,
					0,
					genders.females >= genders.males ? this.$t("common.wom") : this.$t("common.men")
				]
			},
			async stocksTop3Manufacturers( history, topRanges, startTime, endTime, unit ) {
				const style = this.style
				const colors = [ Color("#dad7e7"), Color("#dad7e7").darken(0.2), Color("#dad7e7").darken(0.4) ]

				let topManufacturers = []
				history.forEach( entry => {
					topManufacturers[entry.stock.manufacturer] = topManufacturers[entry.stock.manufacturer] || []
					topManufacturers[entry.stock.manufacturer]++
				})
				const sorted = Object.keys(topManufacturers).map( k => [k, topManufacturers[k]] ).sort( ( a, b ) => b[1] - a[1] )
	
				let dest = this.cards.cosmetics.sections[0].data.lineChartData
				dest.labels = []
				dest.datasets = []
				for ( let i = 0; i < 3 && i < sorted.length; i++ ) {
					dest.datasets.push({
						label: sorted[i][0],
						backgroundColor: colors[i].alpha(0.5).rgbaString(),
						borderColor: colors[i].darken(0.4).rgbaString(),
						data: []
					})
				}

				let destTotal = this.cards.cosmetics.sections[0].data.barChartData
				destTotal.labels = []
				destTotal.datasets = [{
					categoryPercentage: 0.5,
					barPercentage: 1,
					label: this.$t( "statistics.totalused" ),
					backgroundColor: "#dad7e7",
					data: []
				}]

				let destTotalTop3Manufacturer = this.cards.cosmetics.sections[1].data.barChartData
				destTotalTop3Manufacturer.labels = []
				destTotalTop3Manufacturer.datasets = [{
					categoryPercentage: 0.5,
					barPercentage: 1,
					label: sorted.filter( (v, i) => i < 3 ).map( v => v[0] ).join( " + " ),
					backgroundColor: Color("#dad7e7").darken(0.2) .rgbaString(),
					data: []
				}]

				let destTotalTop3 = this.cards.cosmetics.sections[2].data.barChartData
				destTotalTop3.labels = []
				destTotalTop3.datasets = [{
					categoryPercentage: 0.5,
					barPercentage: 1,
					label: topRanges.filter( (v, i) => i < 3 ).map( v => v[0] ).join( " + " ),
					backgroundColor: "#dad7e7",
					data: []
				}]

				let destTopManufacturer = this.cards.cosmetics.sections[1].data.lineChartData
				destTopManufacturer.labels = []
				destTopManufacturer.datasets = [{
					label: ( sorted[0] || [] )[0],
					backgroundColor: Color("#dad7e7").darken(0.2) .rgbaString(),
					borderColor: Color("#dad7e7").darken(0.2) .rgbaString(),
					data: []
				}]

				let time = dayjs( startTime )
				let iHist = 0
				let biggestDay = 0
				while ( time.isBefore(endTime) ) {
					const nextTime = time.add( 1, unit )
					let accums = [ 0, 0, 0 ]
					let total = 0
					let totalTop3 = 0
					let totalTop3Manufacturer = 0
				//	while ( iHist < history.length && this.sameOrBefore( dayjs(history[iHist].date), nextTime ) ) {
					while ( iHist < history.length && dayjs(history[iHist].date).isBefore( nextTime ) ) {
						if ( this.sameOrAfter( dayjs(history[iHist].date), time ) ) {
							let found = null
							let foundRange = null
							for ( let i = 0; i < 3 && i < sorted.length; i++ ) {
								if ( history[iHist].stock.manufacturer == sorted[i][0] ) {
									found = i
								}
								if ( history[iHist].stock.name == topRanges[i][0] ) {
									foundRange = i
								}
							}
							if ( found !== null ) {
								accums[found]++
								totalTop3Manufacturer++
							}
							if ( foundRange !== null ) {
								totalTop3++
							}
							total++
						}
						iHist++
					}
					biggestDay = Math.max( biggestDay, total )
					dest.labels.push( time.format( this.format ) )
					for ( let i = 0; i < 3 && i < sorted.length; i++ ) {
						dest.datasets[i].data.push( accums[i] )
					}
					destTotal.labels.push( time.format( this.format ) )
					destTotal.datasets[0].data.push( total )
					destTotalTop3.labels.push( time.format( this.format ) )
					destTotalTop3.datasets[0].data.push( totalTop3 )
					destTotalTop3Manufacturer.labels.push( time.format( this.format ) )
					destTotalTop3Manufacturer.datasets[0].data.push( totalTop3Manufacturer )
					destTopManufacturer.labels.push( time.format( this.format ) )
					destTopManufacturer.datasets[0].data.push( accums[0] )
					time = nextTime
				}

				this.$refs["card-cosmetics"][0].$refs["layout-0"][0].$refs["lineChart"].update()
				this.$refs["card-cosmetics"][0].$refs["layout-0"][0].$refs["barChart"].update()
				this.$refs["card-cosmetics"][0].$refs["layout-1"][0].$refs["lineChart"].update()
				this.$refs["card-cosmetics"][0].$refs["layout-1"][0].$refs["barChart"].update()
				this.$refs["card-cosmetics"][0].$refs["layout-2"][0].$refs["barChart"].update()
				return { sorted, biggestDay }
			},
			async stocksTop3( history ) {
				let counts = {}
				history.forEach( entry => {
					counts[entry.stock.name] = counts[entry.stock.name] || []
					counts[entry.stock.name]++
				})
				let sorted = Object.keys(counts).map( k => [k, counts[k]] ).sort( ( a, b ) => b[1] - a[1] )

				let chart = this.cards.cosmetics.sections[0].data.pieChartData
				chart.labels = []
				chart.datasets[0] = {
					backgroundColor: ["#dad7e7",  Color("#dad7e7").darken(0.2) .rgbaString(),  Color("#dad7e7").darken(0.4) .rgbaString() ],
					data: []
				}
				for ( let i = 0; i < 3 && i < sorted.length; i++ ) {
					chart.labels.push( sorted[i][0] + " : " + sorted[i][1] )
					chart.datasets[0].data.push( sorted[i][1] )
				}
				this.$refs["card-cosmetics"][0].$refs["layout-0"][0].$refs["pieChart"].update()

				return sorted
			},
			async stocksTop3Types( history, topRanges, topManufacturers ) {
				let countsManufacturer = {}
				let countsRange = {}
				history.forEach( entry => {
					let iMan = topManufacturers.findIndex( v => v[0] == entry.stock.manufacturer )
					let iRan = topRanges.findIndex( v => v[0] == entry.stock.name )
					if ( iMan >= 0 && iMan < 3 ) {
						countsManufacturer[entry.stock.type] = countsManufacturer[entry.stock.type] || []
						countsManufacturer[entry.stock.type]++
					}
					if ( iRan >= 0 && iRan < 3 ) {
						countsRange[entry.stock.type] = countsRange[entry.stock.type] || []
						countsRange[entry.stock.type]++
					}
				})
				let sortedManufacturer = Object.keys(countsManufacturer).map( k => [k, countsManufacturer[k]] ).sort( ( a, b ) => b[1] - a[1] )
				let sortedRange = Object.keys(countsRange).map( k => [k, countsRange[k]] ).sort( ( a, b ) => b[1] - a[1] )

				let chart = this.cards.cosmetics.sections[1].data.pieChartData
				chart.labels = []
				chart.datasets[0] = {
					backgroundColor: [ "#dad7e7",  Color("#dad7e7").darken(0.2) .rgbaString(),  Color("#dad7e7").darken(0.4) .rgbaString() ],
					data: []
				}
				let chart2 = this.cards.cosmetics.sections[2].data.pieChartData
				chart2.labels = []
				chart2.datasets[0] = {
					backgroundColor: [ "#dad7e7",  Color("#dad7e7").darken(0.2) .rgbaString(),  Color("#dad7e7").darken(0.4) .rgbaString() ],
					data: []
				}

				for ( let i = 0; i < 3 && i < sortedManufacturer.length; i++ ) {
					chart.labels.push( sortedManufacturer[i][0] + " : " + sortedManufacturer[i][1] )
					chart.datasets[0].data.push( sortedManufacturer[i][1] )
				}
				for ( let i = 0; i < 3 && i < sortedRange.length; i++ ) {
					chart2.labels.push( sortedRange[i][0] + " : " + sortedRange[i][1] )
					chart2.datasets[0].data.push( sortedRange[i][1] )
				}
				this.$refs["card-cosmetics"][0].$refs["layout-1"][0].$refs["pieChart"].update()
				this.$refs["card-cosmetics"][0].$refs["layout-2"][0].$refs["pieChart"].update()
			},
			async stocksSummary( history, topRange, topManufacturers, biggestDay ) {
				let summary = this.cards.cosmetics.sections[0].data.summaryData
				summary.labels = [
					this.$t( "statistics.toprange" ),
					this.$t( "statistics.topmanufacturer" ),
					this.$t( "statistics.topstock" ),
					this.$t( "statistics.totalused" ),
				]
				summary.data = [
					topRange,
					( topManufacturers[0] || [] )[0],
					biggestDay,
					history.length
				]
			},
			async stocksManufacturersSummary( topManufacturers ) {
				let summary = this.cards.cosmetics.sections[1].data.summaryData
				summary.labels = []
				summary.data = []
				for ( let i = 0; i < 3 && i < topManufacturers.length; i++ ) {
					summary.labels.push( i + 1 )
					summary.data.push( topManufacturers[i][0] )
				}
				summary.labels.push( this.$t( "statistics.totalused" ) )
				summary.data.push( topManufacturers.length )
			},
			async stocksTop3Ranges( history, topRanges, startTime, endTime, unit ) {
				const style = this.style
	
				let dest = this.cards.cosmetics.sections[2].data.lineChartData
				dest.labels = []
				dest.datasets = [{
					label: ( topRanges[0] || [] )[0],
					backgroundColor: Color("#dad7e7").darken(0.2) .rgbaString(),
					borderColor: Color("#dad7e7").darken(0.2) .rgbaString(),
					data: []
				}]

				let time = dayjs( startTime )
				let iHist = 0
				while ( time.isBefore(endTime) ) {
					const nextTime = time.add( 1, unit )
					let accum = 0
				//	while ( iHist < history.length && this.sameOrBefore( dayjs(history[iHist].date), nextTime ) ) {
					while ( iHist < history.length && dayjs(history[iHist].date).isBefore( nextTime ) ) {
						if ( this.sameOrAfter( dayjs(history[iHist].date), time ) && history[iHist].stock.name == topRanges[0][0] ) {
							accum++
						}
						iHist++
					}
					dest.labels.push( time.format( this.format ) )
					dest.datasets[0].data.push( accum )
					time = nextTime
				}

				this.$refs["card-cosmetics"][0].$refs["layout-2"][0].$refs["lineChart"].update()
			},
			async stocksRangesSummary( topRanges ) {
				let summary = this.cards.cosmetics.sections[2].data.summaryData
				summary.labels = []
				summary.data = []
				for ( let i = 0; i < 3 && i < topRanges.length; i++ ) {
					summary.labels.push( i + 1 )
					summary.data.push( topRanges[i][0] )
				}
				summary.labels.push( this.$t( "statistics.totalused" ) )
				summary.data.push( topRanges.length )
			},
			async workersWorkTime( workers, appointments, appointmentsYear, startTime, yearTime, endTime, unit ) {
				const style = this.style
				let charts = []
				for ( let i = 0; i < workers.length + 1; i++ ) {
					let chart = this.cards.staff.sections[i].data.lineChartData
					chart.labels = []
					chart.datasets = []
					chart.datasets[0] = {
						label: this.$t( "statistics.totalhours" ),
						backgroundColor: "#FFCBC1",
						borderColor: Color("#FFCBC1").darken(0.2) .rgbaString(),
						data: []
					}
					charts.push( chart )
				}

				let time = dayjs( startTime )
				let iApp = 0
				let totalWork = Array(workers.length + 1).fill(0)
				let maxWork = Array(workers.length + 1).fill(0)
			//	let maxWorkDate = Array(workers.length + 1).fill(null)

				while ( time.isBefore(endTime) ) {
					const nextTime = time.add( 1, unit )
					let total = 0
					let perWorker = Array(workers.length).fill(0)
					while ( iApp < appointments.length && dayjs(appointments[iApp].date).isBefore( nextTime ) ) {
						if ( dayjs(appointments[iApp].date).isAfter( time ) ) {
							let duration = ( appointments[iApp].timeline ? appointments[iApp].timeline.reduce( ( a, b ) => a + b, 0 ) : 0 )
							if ( appointments[iApp].status && appointments[iApp].status.indexOf( "finished" ) >= 0 ) {
								total += duration
								const iChart = workers.findIndex( w => w.id == appointments[iApp].workers[0] )
								if ( iChart >= 0 ) {
									perWorker[iChart] += duration
								}
							}
						}
						iApp++;
					}
					totalWork[0] += total / 60
					if ( total / 60 > maxWork[0] ) {
						maxWork[0] = total / 60
					//	maxWorkDate[0] = dayjs(time)
					}
					charts[0].labels.push( time.format( this.format ) )
					charts[0].datasets[0].data.push( total / 60 )
					for ( let i = 0; i < workers.length; i++ ) {
						if ( perWorker[i] / 60 > maxWork[i + 1] ) {
							maxWork[i + 1] = perWorker[i] / 60
					//		maxWorkDate[i + 1] = dayjs(time)
						}
						totalWork[i + 1] += perWorker[i] / 60
						charts[i + 1].labels.push( time.format( this.format ) )
						charts[i + 1].datasets[0].data.push( perWorker[i] / 60 )
					}
					time = nextTime
				}

				this.$refs["card-staff"][0].$refs["layout-0"][0].$refs["lineChart"].update()
				for ( let i = 0; i < workers.length; i++ ) {
					this.$refs["card-staff"][0].$refs["layout-" + (i + 1)][0].$refs["lineChart"].update()
				}

				let maxWorkHour = Array(workers.length + 1).fill().map(Object)
				let maxWorkDate = Array(workers.length + 1).fill().map(Object)
				let maxWorkMonth = Array(workers.length + 1).fill().map(Object)

				for ( let iApp = 0; iApp < appointmentsYear.length; iApp++ ) {
					if ( appointmentsYear[iApp].status && appointmentsYear[iApp].status.indexOf( "finished" ) >= 0 ) {
						let duration = ( appointmentsYear[iApp].timeline ? appointmentsYear[iApp].timeline.reduce( ( a, b ) => a + b, 0 ) : 0 )
						const iWorker = workers.findIndex( w => w.id == appointmentsYear[iApp].workers[0] ) + 1
						const date = dayjs( appointmentsYear[iApp].date )
						let hour = date.hour()
						let day = date.year() * 1000 + date.dayOfYear() - 1
						let month = date.year() * 100 + date.month()
						maxWorkMonth[iWorker][month] = maxWorkMonth[iWorker][month] || 0
						maxWorkMonth[iWorker][month]++
						maxWorkDate[iWorker][day] = maxWorkDate[iWorker][day] || 0
						maxWorkDate[iWorker][day]++
						for ( let i = 0; i < Math.floor(duration / 60); i++ ) {
							maxWorkHour[iWorker][hour + i] = maxWorkHour[iWorker][hour + i] || 0
							maxWorkHour[iWorker][hour + i]++
						}
						let endHour = Math.floor(duration / 60)
						maxWorkHour[iWorker][hour + endHour] = maxWorkHour[iWorker][hour + endHour] || 0
						maxWorkHour[iWorker][hour + endHour] += ( duration - Math.floor(duration / 60) * 60 ) / 60
					}
				}
				for ( let i = 0; i < workers.length + 1; i++ ) {
					if ( Object.keys(maxWorkHour[i]).length > 0 ) {
						maxWorkHour[i] = Object.keys(maxWorkHour[i]).reduce( (a, key) => a === 0 || maxWorkHour[i][key] > maxWorkHour[i][a] ? key : a )
					} else {
						maxWorkHour[i] = ""
					}
					if ( Object.keys(maxWorkDate[i]).length > 0 ) {
						maxWorkDate[i] = Object.keys(maxWorkDate[i]).reduce( (a, key) => a === 0 || maxWorkDate[i][key] > maxWorkDate[i][a] ? key : a )
						maxWorkDate[i] = dayjs().year( maxWorkDate[i] / 1000 ).dayOfYear( maxWorkDate[i] % 1000 + 1 )
					} else {
						maxWorkDate[i] = ""
					}
					if ( Object.keys(maxWorkMonth[i]).length > 0 ) {
						maxWorkMonth[i] = Object.keys(maxWorkMonth[i]).reduce( (a, key) => a === 0 || maxWorkMonth[i][key] > maxWorkMonth[i][a] ? key : a )
						maxWorkMonth[i] = dayjs().year( maxWorkMonth[i] / 100 ).month( maxWorkMonth[i] % 100 )
					} else {
						maxWorkMonth[i] = ""
					}
				}

			//	return { maxWork, maxWorkDate, totalWork }
				return { maxWork, totalWork, maxWorkHour, maxWorkDate, maxWorkMonth }
			},
			async workersDays( workers, absences, startTime, endTime, unit ) {
				const style = this.style
				let presences = new Array(workers.length + 1).fill( 0 )

				let mainSchedule = this.$store.getters.getSchedules().sort( ( a, b ) => a.id - b.id )
				for ( var i = 0; i < mainSchedule.length; i++ ) {
					let sched = mainSchedule[i]
					if ( sched.morningop != "0" || sched.morningclos != "0" || sched.afternop != "0" || sched.afternclos != "0" ) {
						mainSchedule[i] = true
					} else {
						mainSchedule[i] = false
					}
				}
				workers.forEach( worker => {
					worker.presence = [ false, false, false, false, false, false, false ]
					if ( worker.schedule && worker.schedule.length > 0 ) {
						let sched = worker.schedule
						for ( var i = 0; i < sched.length; i++ ) {
							var dayIdx = sched[i][0] - 1
							if ( sched[i].length > 0 ) {
								worker.presence[dayIdx] = true
							}
						}
					} else {
						worker.presence = mainSchedule
					}
				})

				let charts = []
				for ( let i = 0; i < workers.length + 1; i++ ) {
					let chart = this.cards.staff.sections[i].data.barChartData
					chart.labels = []
					chart.datasets = []
					chart.datasets[0] = {
						categoryPercentage: 0.5,
						barPercentage: 1,
						label: this.$t( "statistics.attendance" ),
						backgroundColor: "#FFCBC1",
						borderColor: Color("#FFCBC1").darken(0.2) .rgbaString(),
						data: []
					}
					charts.push( chart )
				}

				if ( unit == "hour" ) {
					unit = "day"
				}
				/*if ( unit == "hour" ) {
					// TBD
				} else*/ {
					let time = dayjs( startTime ).hour( 12 )
					while ( time.isBefore(endTime) ) {
						const nextTime = time.add( 1, unit )
						let subtime = dayjs(time)
						let accums = new Array(charts.length).fill(0)
						while ( subtime.isBefore(nextTime) ) {
							let weekday = ( parseInt(subtime.format( "d" )) + 6 ) % 7
							if ( mainSchedule[weekday] ) {
								accums[0]++
								for ( let i = 0; i < workers.length; i++ ) {
								//	let absent = ( absences.find( abs => abs.worker.id == workers[i].id && abs.status && abs.status.indexOf("validated") >= 0 && this.sameOrAfter(subtime, abs.start) && ( this.sameOrBefore(subtime, abs.end) || ( abs.end == abs.start && this.sameOrBefore(subtime, dayjs(abs.end).hour(23)) ) ) ) != null )
									let absent = ( absences.find( abs => abs.worker.id == workers[i].id && abs.status && abs.status.indexOf("validated") >= 0 && this.sameOrAfter(subtime, abs.start) && ( subtime.isBefore(abs.end) || ( abs.end == abs.start && this.sameOrBefore(subtime, dayjs(abs.end).hour(23)) ) ) ) != null )
									if ( !absent && workers[i].presence[weekday] ) {
										accums[i + 1]++
									}
								}
							}
							subtime = subtime.add( 1, "day" )
						}
						for ( let i = 0; i < charts.length; i++ ) {
							charts[i].labels.push( time.format( this.format ) )
							charts[i].datasets[0].data.push( accums[i] )
							presences[i] += accums[i]
						}
						time = nextTime
					}
				}

				for ( let i = 0; i < workers.length + 1; i++ ) {
					this.$refs["card-staff"][0].$refs["layout-" + i][0].$refs["barChart"].update()
				}

				return presences
			},
			async workersPresence( workers, absences, presences, start, now ) {
				const style = this.style
				let schedules = this.$store.state.schedules
				const totalDays = dayjs(now).diff(start, "day")
/*
				let presences = new Array(workers.length).fill( totalDays )
				absences.forEach( abs => {
					if ( abs.status == "validated" ) {
						presences[workers.findIndex(w => w.id == abs.worker.id)] -= dayjs(abs.start).diff(abs.end, "day" )
						console.log( dayjs(abs.end).format( "HH:mm:ss" ) )
						if ( dayjs(abs.end).format( "HH:mm:ss" ).indexOf( "00:00:00" ) >= 0 ) {
							presences[workers.findIndex(w => w.id == abs.worker.id)]++
						}
					}
				})
*/
				const presencesPercent = presences[0] > 0 ?
					presences.filter( (p, i) => i > 0 ).map( p => parseInt(100 * p / presences[0]) ) :
					[]

				let chart = this.cards.staff.sections[0].data.pieChartData
				chart.labels = presencesPercent.map( (p, i) => workers[i].name + " : " + p + "%" )
				chart.datasets[0] = {
					backgroundColor: workers.map( w => w.color ),
					data: presencesPercent
				}
				this.$refs["card-staff"][0].$refs["layout-0"][0].$refs["pieChart"].update()

				for ( let i = 1; i < presences.length; i++ ) {
					let chart = this.cards.staff.sections[i].data.pieChartData
					chart.labels = [ this.$t("common.present") + " : " + this.$tc("time.days", presences[i]), this.$t("common.absent") + " : " + this.$tc("time.days", presences[0] - presences[i]) ]
					chart.datasets[0] = {
						backgroundColor: [Color("#FFCBC1").darken(0.2) .rgbaString(), "#FFCBC1" ],
						data: [ presences[i], presences[0] - presences[i] ]
					}
					this.$refs["card-staff"][0].$refs["layout-" + i][0].$refs["pieChart"].update()
				}
			},
			async workersSummary( workers, absences, presences, worktime ) {
				let baseSummary = this.cards.staff.sections[0].data.summaryData
				baseSummary.labels = [
					this.$t( "statistics.totalhours" ),
					this.$t( "statistics.totaldays" ),
					this.$t( "statistics.absences" ),
					// this.$t( "statistics.bestworker" )
				]
				console.log( "worktime.totalWork", worktime.totalWork )
				console.log( "workers", workers )
				baseSummary.data = [
					this.$tc( "time.shorthours", parseInt(worktime.totalWork[0]) ),
					presences[0],
					absences.filter( abs => abs.status == "validated" && abs.worker.username != "general" ).length,
					// workers[ worktime.totalWork.reduce( ( max, x, i, arr ) => ( i > 0 && x > arr[max] ) ? i : max, 1 ) - 1 ].name
				]

				for ( let i = 0; i < workers.length; i++ ) {
					let summary = this.cards.staff.sections[i + 1].data.summaryData
					/*
					summary.labels = [
						this.$t( "statistics.topworkhours" ),
						this.$t( "statistics.topwork." + this.unit ),
						this.$t( "statistics.absences" )
					]
					summary.data = [
						this.$tc( "time.shorthours", parseInt(worktime.maxWork[i + 1]) ),
						worktime.maxWorkDate[i + 1] ? worktime.maxWorkDate[i + 1].format( this.unitformat ) : "",
						absences.filter( abs => abs.status == "validated" && abs.worker.id == workers[i].id ).length
					]
					*/
					summary.labels = [
						this.$t( "statistics.topwork.hour" ),
						this.$t( "statistics.topwork.day" ),
						this.$t( "statistics.topwork.month" ),
						this.$t( "statistics.absences" )
					]
					summary.data = [
						worktime.maxWorkHour[i + 1] ? this.$tc( "time.shorthours", worktime.maxWorkHour[i + 1] ) : "",
						worktime.maxWorkDate[i + 1] ? worktime.maxWorkDate[i + 1].format( "DD MMM" ) : "",
						worktime.maxWorkMonth[i + 1] ? worktime.maxWorkMonth[i + 1].format( "MMM" ) : "",
						absences.filter( abs => abs.status == "validated" && abs.worker.id == workers[i].id ).length
					]
				}
			},
			async update() {
				this.isLoading = true
				let t = performance.now()
				console.log( "Stats 1 :" + ( performance.now() - t ) )
				this.style = getComputedStyle(document.body)
				console.log( "Stats 2 :" + ( performance.now() - t ) )

				let schedules = this.$store.state.schedules
				let openHour = 23
				let closeHourMorning = 23
				let openHourAfternoon = 0
				let closeHour = 0
				for ( var i = 0; i < schedules.length; i++ ) {
					if ( schedules[i].morningop != "0" ) {
						openHour = Math.min( openHour, schedules[i].morningop.split(":")[0] )
					}
					if ( schedules[i].morningclos != "0" ) {
						closeHourMorning = Math.min( closeHourMorning, schedules[i].morningclos.split(":")[0] )
					}
					if ( schedules[i].afternop != "0" ) {
						openHourAfternoon = Math.max( openHourAfternoon, schedules[i].afternop.split(":")[0] )
					}
					if ( schedules[i].afternclos != "0" ) {
						closeHour = Math.max( closeHour, schedules[i].afternclos.split(":")[0] )
					}
				}

				console.log( "Stats 3 :" + ( performance.now() - t ) )
				var start = new Date()
				
				if ( this.period == "year" ) {
					start = start.addDays( -365 - 1 )
				} else if ( this.period == "month" ) {
					start = start.addDays( -30 )
					start.setDate( 1 )
				} else if ( this.period == "week" ) {
					start = start.addDays( -7 )
				}
				
				/*
				if ( this.period == "year" ) {
					start.setMonth( 0 )
				}
				if ( this.period == "year" || this.period == "month" ) {
					start.setDate( 1 )
				}
				if ( this.period == "week" ) {
					while ( start.getDay() != 1 ) {
						start = start.addDays( -1 )
					}
				}
				*/
				start.setHours( 0 )
				start.setMinutes( 0 )
				start.setSeconds( 0 )
				start.setMilliseconds( 0 )
				var now = new Date()
				now.setHours( 23 )
				now.setMinutes( 59 )
				const startString = dayjs( start ).format( "YYYY-MM-DD HH:mm" )
				const nowString = dayjs( now ).format( "YYYY-MM-DD HH:mm" )
				console.log( "Stats 4 :" + ( performance.now() - t ) )

				let appointments = ( await this.$api.appointments.get({ "date[gte]": startString, "date[lte]": nowString, "{sort}": "asc:date" }).catch( error => console.log( error ) ) ).data
				console.log( "Stats 5 :" + ( performance.now() - t ) )
				let usersIds = []
				for ( let i = 0; i < appointments.length; i++ ) {
					let app = appointments[i]
					app.duration = ( app.timeline ? app.timeline.reduce( ( a, b ) => a + b, 0 ) : 0 )
					if ( app.userid && app.userid > 0 ) {
						usersIds.push( app.userid )
						/*
						promises.push( this.$store.getters.getUser( app.userid ).then( response => {
							app.user = response.data[0]
						}) )
						*/
					}
				}
				const allUsers = ( await this.$api.salonsusers.get( { "id[in]": usersIds } ) ).data
				for ( let i = 0; i < appointments.length; i++ ) {
					appointments[i].user = allUsers.find( u => u.id == appointments[i].userid )
				}
				console.log( "allUsers", allUsers )
				console.log( "Stats 6 :" + ( performance.now() - t ) )

				let allPromises = []
				let f = null
				f = async () => {
					const topAppointmentsPromise = this.appointmentsByDate( appointments, start, now, this.unit )
					const minmax = await this.appointmentsByHour( openHour, closeHour, closeHourMorning, openHourAfternoon, appointments )
					const counts = await this.appointmentsTakers( appointments, now )
					this.appointmentsSummary( appointments, counts, minmax.max )
					const ratio = await this.appointmentsFinishedCanceled( appointments )
					this.appointmentsGeneralSummary( appointments, ratio, minmax.min )
					this.appointmentsOnlineSummary( appointments, counts, minmax.minOnline, minmax.maxOnline, await topAppointmentsPromise, counts.topAge )
				}
				allPromises.push( f() )
				console.log( "Stats 7 :" + ( performance.now() - t ) )
				Promise.all( allPromises ).then( () => {
					this.isLoading = false
				})

				f = async () => {
					const usersCount = parseInt( ( await this.$api.salonsusers.count().catch( error => console.log( error ) ) ).data[0].count )
					const baseUsersCount = parseInt( ( await this.$api.salonsusers.count({ "registration[lt]": startString }).catch( error => console.log( error ) ) ).data[0].count )
					let users = ( await this.$api.salonsusers.get({
						"{or}": [
							{
								"coachsusers.registration[gte]": startString,
								"coachsusers.registration[lte]": nowString
							},
							{
								"coachsusers.unregistration[gte]": startString,
								"coachsusers.unregistration[lte]": nowString
							}
						]
					}).catch( error => console.log( error ) ) ).data
					const usersBirthdates = ( await this.$api.salonsusers.get( { "{join:left}": { users: { "{users.id}": "coachsusers.userid" } }, "{coalesce}": ["birthdate"], "{fields}": [] } ).catch( error => console.log( error ) ) ).data
					/*
					const topUsers = ( await this.$api.appointments.count({ "{group}": "userid", "{max}": 100, "{sort}": "desc:count(userid)", "{fields}": ["userid"] }).catch( error => console.log( error ) ) ).data
					for ( let i in topUsers ) {
						if ( topUsers[i].userid ) {
							topUsers[i].user = ( await this.$store.getters.getUser( topUsers[i].userid ) ).data[0]
						}
					}
					*/
					const topUsers = ( await this.$api.salonsusers.get( { "{join:left}": { users: { "{users.id}": "coachsusers.userid" } }, "{coalesce}": ["fname", "lname", "phone"], "{fields}": ["loyalty", "coachsusers.id"], "{sort}": "desc:loyalty", "{max}": 100, "coachsusers.unregistration": "" } ).catch( error => console.log( error ) ) ).data

					this.usersByDate( users, baseUsersCount, start, now, this.unit )
					const ageTop = await this.usersByAge( usersBirthdates, now )
					this.usersSummary( usersCount, baseUsersCount, users, ageTop )
					this.usersTop( topUsers )
					this.usersAnonymous( appointments, start, now, this.unit )
					const genders = await this.usersGenders( users )
					this.usersDetailsSummary( users, genders )
				}
				allPromises.push( f() )
				console.log( "Stats 8 :" + ( performance.now() - t ) )

				f = async () => {
					let history = ( await this.$api.stockshistory.get({ "date[gte]": startString, "date[lte]": nowString, "{sort}": "asc:date" }).catch( error => console.log( error ) ) ).data
					console.log(history)
					if ( history.length ) {
						this.cards.cosmetics.hasData = true
						// this.cards.cosmetics.hide = false
						this.$nextTick( async () => {
							history.forEach( entry => {
								entry.stock = this.$store.getters.getStock( entry.stockid )
							})
							const topRanges = await this.stocksTop3( history )
							const topManufacturers = await this.stocksTop3Manufacturers( history, topRanges, startString, nowString, this.unit )
							this.stocksSummary( history, ( topRanges[0] || [] )[0], topManufacturers.sorted, topManufacturers.biggestDay )
							this.stocksTop3Types( history, topRanges, topManufacturers.sorted )
							this.stocksManufacturersSummary( topManufacturers.sorted )
							this.stocksTop3Ranges( history, topRanges, startString, nowString, this.unit )
							this.stocksRangesSummary( topRanges )
						})
					} else {
						this.cards.cosmetics.hasData = false
					}
				}
				allPromises.push( f() )
				console.log( "Stats 9 :" + ( performance.now() - t ) )

				f = async () => {
					let workers = this.$store.getters.getWorkers().filter( w => w.username != "general" )
					let general = this.$store.getters.getGeneralWorker()
					let absences = ( await this.$api.absences.get({ "{or}": [ { "start[gte]": startString, "start[lte]": nowString }, { "end[gte]": startString, "end[lte]": nowString } ] }).catch( error => console.log( error ) ) ).data
					absences = absences.filter( a => a.worker != general.id )
					absences.forEach( abs => {
						abs.worker = workers.find( w => w.id == abs.worker )
					})

					absences = absences.filter(w => w.worker)
					console.log( "absences", absences )
					workers.forEach( worker => {
						worker.section = {
							title: worker.name,
							data: {
								barChartData: { labels: [], datasets: [] },
								lineChartData: { labels: [], datasets: [] },
								pieChartData: { datasets: [] },
								summaryData: { labels: [], data: [] },
								lineChartOptions: this.cards.staff.sections[0].data.lineChartOptions,
								lineChartTitle: this.$t( "statistics.hours" ),
								barChartTitle: this.$t( "statistics.days" ),
								pieChartTitle: this.$t( "statistics.attendancerate" ),
								summaryTitle: this.$t( "statistics.inshort" )
							}
						}
						this.$set( this.cards.staff.sections, this.cards.staff.sections.length, worker.section )
					})

					this.$nextTick( async () => {
						let allStart = dayjs( (new Date()).addDays(-365) ).format( "YYYY-MM-DD HH:mm" )
						let appointmentsYear = ( await this.$api.appointments.get({ "date[gte]": allStart, "date[lte]": nowString, "{sort}": "asc:date", "{fields}": [ "date", "status", "timeline", "workers" ] }).catch( error => console.log( error ) ) ).data
						const worktime = await this.workersWorkTime( workers, appointments, appointmentsYear, startString, allStart, nowString, this.unit )
						const presences = await this.workersDays( workers, absences, start, now, this.unit )
						this.workersPresence( workers, absences, presences, start, now )
						this.workersSummary( workers, absences, presences, worktime )
					})

				}
				allPromises.push( f() )
				console.log( "Stats final :" + ( performance.now() - t ) )
				Promise.all( allPromises ).then( () => {
				//	this.isLoading = false
				})
			}
		},
		created() {
			this.update()
		}
	}
</script>


<style scoped lang="scss" src="../css/pages/stats.scss"/>
