<template>
	<div>
		<div class="row">
			<div v-if="data.lineChartData" id="col-top-left" class="col-md-6">
				<div class="total">{{ data.lineChartTitle }}</div>
				<div class="chart">
					<line-chart ref="lineChart" :data="data.lineChartData" :width="500" :height="mobile ? 500 : 300" :options="{ ...lineChartOptions, ...(data.lineChartOptions || {}) }"></line-chart>
				</div>
			</div>
			<div v-if="data.barChartData" class="col-md-6">
				<div class="total">{{ data.barChartTitle }}</div>
				<div class="chart">
					<bar-chart ref="barChart" :data="data.barChartData" :width="500" :height="mobile ? 500 : 300" :options="{ ...barChartOptions, ...(data.barChartOptions || {}) }"></bar-chart>
				</div>
			</div>
			<div v-if="data.listData" class="col-md-12">
				<ul class="top-list">
					<li v-for="item in data.listData">
						<div class="top">
							<router-link :to="item.link">
								{{ item.text }}
							</router-link>
							<div class="details">
								<div v-for="( detail, i ) in item.details">
								<a v-if="typeof detail == 'object'" :href="detail[Object.keys(detail)[0]]">
									{{ Object.keys(detail)[0] }}
								</a>
								<template v-else>
									{{ detail }}
								</template>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div class="row part2">
			<div v-if="data.summaryData" id="block-resume" class="circle-block col-md-6">
				<div class="resume-bg">
					<div class="total">{{ data.summaryTitle }}</div>
					<div class="resume row" v-for="( value, i ) in ( data.summaryData || {} ).data">
						<div class="col-8">
							<span class="text-resume">{{ data.summaryData.labels[i] }}</span>
						</div>
						<div class="col-4 d-flex">
							<div class="nb-resume">
								<div class="content">{{ value }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="data.pieChartData" id="col-bottom-right" class="col-md-6">
				<div class="total">{{ data.pieChartTitle }}</div>
				<div class="chart pie">
					<pie-chart ref="pieChart" :data="data.pieChartData" :responsive="false" :width="380" :height="200" :options="{ ...pieChartOptions, ...(data.pieChartOptions || {}) }"></pie-chart>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import PieChart from "../../components/PieChart.js";
	import BarChart from "../../components/BarChart.js";
	import LineChart from "../../components/LineChart.js";
	
	export default {
		components: {
			PieChart,
			BarChart,
			LineChart,
		},
		props: [
			"data"
		],
		data() {
			var style = getComputedStyle(document.body);
			var primCol = style.getPropertyValue('--color-primary-light-10');
			var seconCol = style.getPropertyValue('--color-secondary-light-10');
			var terCol = style.getPropertyValue('--color-tertiary-light-10');
			return {
				mobile: false,
				prestations: [1,2,3],
				pieChartOptions: {
					legend: {
						position: "right",
					},
					responsive: false,
					hoverBorderWidth: 20,
					tooltips: { enabled: false }
				},
				barChartOptions: {
					responsive: true,
					maintainAspectRatio: true,
					scales: {
						xAxes: [{
							stacked: true,
						//	categoryPercentage: 0.5,
						//	barPercentage: 1,
						}],
						yAxes: [{
							stacked: true,
							ticks: {
								min: 0,
								stepSize: 1
							},
						}]
					},
					legend: {
						position: "bottom",
					}
				},
				lineChartOptions: {
					responsive: true,
					maintainAspectRatio: true,
					scales: {
						yAxes: [{
							ticks: {
								stepSize: 1,
								min: 0,
								suggestedMax: 1,
								suggestedMin: 0,
								beginAtZero: true
							},
						}]
					},
					legend: {
						position: "bottom",
					}
				},
			}
		},
		/*
		methods: {
			resize() {
				let goMobile = false
				if ( window.innerWidth < 768 ) {
					goMobile = true
				}
				console.log( "goMobile", goMobile )
				if ( goMobile != this.mobile ) {
					this.mobile = goMobile
					if ( !goMobile ) {
						this.lineChartOptions.responsive = false
						this.barChartOptions.responsive = false
					}
				//	this.lineChartOptions.maintainAspectRatio = false
				//	this.barChartOptions.maintainAspectRatio = false
					this.$nextTick( () => {
						if ( this.$refs.lineChart ) {
							console.log( "update height" )
							let ctx = this.$refs.lineChart.$refs.canvas.getContext("2d")
						//	if ( goMobile ) {
								this.$refs.lineChart.$refs.canvas.setAttribute( "height", 600 )
								ctx.canvas.height = 600
						//	} else {
						//		this.$refs.lineChart.$refs.canvas.setAttribute( "height", 300 )
						//		ctx.canvas.height = 300
						//	}
						}
						if ( this.$refs.barChart ) {
						}
						this.$forceUpdate()
						this.$nextTick( () => {
							if ( this.$refs.lineChart ) {
								this.$refs.lineChart.update()
							}
							if ( this.$refs.barChart ) {
								this.$refs.barChart.update()
							}
						})
					})
				}
			}
		},
		mounted() {
			this.resize()
			window.addEventListener( "resize", this.resize )
		},
		*/
		created() {
			if ( window.innerWidth < 768 ) {
				this.mobile = true
			} else {
				this.mobile = false
			}
		}
	}
	</script>
