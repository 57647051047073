<template>
	<div class="card">
		<div class="card-sticky">
			<div :id="'card-header-'+ name" class="card-header d-flex row">
				<div class="col-md-4 d-flex">
					<div class="drop">
						<Popper
							class="dropdown"
							ref="periodDrop"
							tagName="div"
							trigger="clickToToggle"
							:options="{ placement: 'bottom' }"
							:visible-arrow="false"
						>
							<div class="dropdown-menu">
								<div class="dropdown-choices">
									<button
										class="dropdown-item"
										type="button"
										v-for="p in ['day', 'week', 'month', 'year'/*, 'custom'*/]"
										:key="p"
										@click="period = p; $emit('period-changed', p); $refs.periodDrop.doClose()"
									>{{ $t('statistics.' + p) }}</button>
								</div>
							</div>
							<button
								:id="'block-' + name + 'freq-period'"
								slot="reference"
								aria-expanded="false"
								aria-haspopup="true"
								class="btn btn-gray dropdown-toggle tempo"
								data-flip="false"
								data-toggle="dropdown"
								type="button"
							>
								<span class="desktop">
									{{ period ? $t('statistics.' + period) : $t('statistics.periods') }}
								</span>
								<i class="fas fa-history mobile" />
							</button>
						</Popper>
					</div>
				</div>
				<div class="col-md-4">
					<h2 class="d-inline-block title">{{ title }}</h2>
				</div>
				<!--div class="d-inline-block col-md-4">
					<div class="drop right">
						<Popper
							class="dropdown d-block"
							trigger="clickToToggle"
							:options="{ placement: 'bottom' }"
							:visible-arrow="false"
						>
							<div class="dropdown-menu">
								<div class="dropdown-choices">
									<button
										class="dropdown-item"
										type="button"
										v-for="p in ['yesterday', 'thisWeek', 'thisMonth', 'thisYear', 'custom']"
										:key="p"
										@click="comparePeriod = p"
									>{{ $t('statistics.' + p) }}</button>
								</div>
							</div>
							<button
								slot="reference"
								aria-expanded="false"
								aria-haspopup="true"
								class="btn btn-gray dropdown-toggle tempo"
								data-flip="false"
								data-toggle="dropdown"
								name="btn-stocks"
								type="button"
								value=""
							>{{ comparePeriod ? $t('statistics.' + comparePeriod) : $t('statistics.compare') }}</button>
						</Popper>
					</div>
				</div-->
			</div>
			<div :id="'block-' + name + '-titles'" class="slider carousel slide2" data-index="0" data-interval="0" :data-max-index="fixSections.length" data-speed="350">
				<div ref="titles1" class="carousel-inner">
					<div v-for="( section, index ) in fixSections" :key="'t-' + name + '-' + section.id + '-' + index" :class="'carousel-item' + ( index == 0 ? ' active' : '' )">
						<h3>{{section.title}}</h3>
					</div>
				</div>
				<div class="carousel-control-prev" data-slide="prev" :data-targets="'#block-' + name + '-titles #block-' + name + '-titles2 #block-' + name">
					<div></div>
				</div>
				<div class="carousel-control-next" data-slide="next" :data-targets="'#block-' + name + '-titles #block-' + name + '-titles2 #block-' + name">
					<div></div>
				</div>
			</div>
		</div>
		<div :id="'block-' + name" class="block-cat carousel slide2" data-index="0" data-interval="0" :data-max-index="realSections.length" data-speed="350">
			<div :class="'carousel-inner' + ( onResponsive ? ' responsive' : '' )">
				<div v-for="( section, index ) in realSections" :key="'c-' + name + '-' + section.id + '-' + index" :id="'section-' + name + '-' + index" :class="'carousel-item' + ( index == 0 ? ' active' : '' )">
					<MainLayout :ref="'layout-' + index" :data="section.data"/>
				</div>
			</div>
			<div class="carousel-control-prev" data-slide="prev" :data-targets="'#block-' + name + '-titles #block-' + name + '-titles2 #block-' + name">
				<div class="btn btn-primary left-arrow pc-mod">
					<i class="fas fa-angle-left"></i>
				</div>
			</div>
			<div class="carousel-control-next" data-slide="next" :data-targets="'#block-' + name + '-titles #block-' + name + '-titles2 #block-' + name">
				<div class="btn btn-primary right-arrow pc-mod">
					<i class="fas fa-angle-right"></i>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import MainLayout from './MainLayout.vue';
	import carousel from '../../components/carousel.js';
	import Popper from 'vue-popperjs'

	export default {
		components: {
			MainLayout,
			Popper
		},
		props: [
			"name",
			"title",
			"sections",
		],
		data() {
			return {
				period: null,
				comparePeriod: null,
				onResponsive: false
			}
		},
		computed: {
			fixSections() {
				if (this.sections.length == 1) {
					return new Array(4).fill({ id: this.sections[0].title + "-", title: this.sections[0].title })
				}
				if (this.sections.length == 2) {
					return [ ...this.sections.map(s => ({ title: s.title, id: s.title })), ...this.sections.map(s => ({ title: s.title, id: s.title + "-" })) ]
				}
				if (this.sections.length == 3) {
					return [ ...this.sections.map(s => ({ title: s.title, id: s.title })), ...this.sections.map(s => ({ title: s.title, id: s.title + "-" })) ]
				}
				return this.sections.map(s => ({ title: s.title, id: s.title }))
			},
			realSections() {
				return this.sections.map(s => ({ ...s, id: s.title }))
			}
		},
		watch: {
			sections() {
				this.$nextTick( () => {
					this.initCarouselPosition()
				})
			}
		},
		methods: {
			setPeriod( period ) {
				this.period = period
			},
			goUp() {
				var options = {
					easing: 'ease-in',
					offset: -60,
					force: true,
					x: false,
					y: true
				};
				this.$scrollTo(this.$el, 300, options)
			},
			changeDiff() {
			},
			initCarouselPosition() {
				let last1 = this.$refs.titles1.children[ this.$refs.titles1.children.length - 1 ]
				last1.parentElement.removeChild( last1 )
				this.$refs.titles1.prepend( last1 )

				// let last2 = this.$refs.titles2.children[ this.$refs.titles2.children.length - 1 ]
				// last2.parentElement.removeChild( last2 )
				// this.$refs.titles2.prepend( last2 )
			},
			moved( elem ) {
				if ( elem.getAttribute("id") ) {
					let id = parseInt( elem.getAttribute("id").match( /\d+/g ) )
					if ( id ) {
						if (this.realSections[id].data.listData ) {
							this.onResponsive = true
						} else {
							this.onResponsive = false
						}
					}
				}
			}
		},
		mounted() {
			carousel.install( this, this.moved )
			this.initCarouselPosition()
		}
	}
</script>
